.image-gallery {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.image-gallery-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    line-height: 0;
    
    &.fullscreen {
      background: black;
    }
  
    .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 80px); // 80 px for the thumbnail space
    }
  
    &.left,
    &.right {
      .image-gallery-slide .image-gallery-image {
        max-height: 100vh;
      }
    }
  }

.image-gallery-slides {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .image-gallery-slide {
    width: 100%;
    display: flex;
    max-height: 100%;
    min-height: 100%;
    overflow: hidden;
    align-self: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
      
    &.center {
      position: relative;
    }

    @media screen and (max-width: 768px) {
        height: 50vh;
    }

    @media screen and (max-width: 400px) {
        height: 40vh;
    }
  
    .image-gallery-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  
    .image-gallery-description {
        display: none;
    }
}
  

.image-gallery-slide-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &.left,
    &.right {
      max-width: calc(80% - 10px); // 200px + 10px for margin
      min-width: calc(80% - 10px); // 200px + 10px for margin
    }
    &.image-gallery-rtl {
      direction: rtl;
    }

    @media screen and (max-width: 768px) {
        &.left,
        &.right {
            max-width: calc(80% - 10px); // 100px + 10px for margin
            min-width: calc(80% - 10px); // 100px + 10px for margin
        }
    }

    @media screen and (max-width: 400px) {
        &.left,
        &.right {
            max-width: calc(80% - 10px); // 60px + 10px for margin
            min-width: calc(80% - 10px); // 60px + 10px for margin
        }
    }
  }

.image-gallery-thumbnail {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 5px;
    border: solid 2px white;


    &.active, 
    &:focus {
        border: solid 3px green;
    }

    // @media screen and (max-width: 768px) {
    //     width: 100px;
    // }

    // @media screen and (max-width: 400px) {
    //     width: 60px;
    // }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
          outline: none;
          border: 3px solid green;
        }
    }
}

.image-gallery-thumbnails-wrapper {
    position: relative;
    justify-content: center;
    &.thumbnails-swipe-horizontal {
        touch-action: pan-y;
    }
  
    &.thumbnails-swipe-vertical {
        touch-action: pan-x;
    }
  
    &.thumbnails-wrapper-rtl {
      direction: rtl;
    }
    &.left,
    &.right {
        display: inline-block;
        vertical-align: top;
        width: 20%;
  
        .image-gallery-thumbnails {
            height: 100%;
            width: 100%;
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
  
            .image-gallery-thumbnail {
                display: block;
                margin-right: 0;
                padding: 0;
  
                + .image-gallery-thumbnail {
                    margin-left: 0;
                    margin-top: 2px;
                }
            }
        }
    }
  
    &.left,
    &.right {
        margin: 0 5px;
    }

    // @media screen and (max-width: 768px) {
    //     &.left,
    //     &.right {
    //         width: 100px;
    //     }
    // }

    // @media screen and (max-width: 400px) {
    //     &.left,
    //     &.right {
    //         width: 60px;
    //     }
    // }
}

.image-gallery-thumbnails {
    width: 100%;
    padding: 5px 0;

    // @media screen and (max-width: 768px) {
    //     width: 100px;

    //     .image-gallery-thumbnails-container {
    //         width: 100px;
    //     }
    // }

    // @media screen and (max-width: 400px) {
    //     width: 60px;

    //     .image-gallery-thumbnails-container {
    //         width: 100px;
    //     }
    // }
  
    .image-gallery-thumbnails-container {
        width: 100%;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
    }
}

.image-gallery-thumbnail-inner {
    width: 100%;
    display: block;
    position: relative;

    // @media screen and (max-width: 768px) {
    //     width: 100px;
    // }

    // @media screen and (max-width: 400px) {
    //     width: 60px;
    // }
}

.image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
    margin: 0% 1%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    padding: 10px;
    overflow: hidden;
    box-shadow: none;
    align-self: center;
    border-radius: 25px;
    box-sizing: border-box;
    background-color: green;
    transition: all 0.5s ease;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    .image-gallery-svg {
        box-shadow: none;
        display: none;
    }
}

.image-gallery-left-nav {
    box-shadow: none;
    background-size: 75% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../common/custom_components/carousel/assets/back.svg);

    &:hover { opacity: 1; }
}

.image-gallery-right-nav {
    box-shadow: none;
    background-image: url(../../../common/custom_components/carousel/assets/front.svg);
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: center;

    &:hover {
        opacity: 1;
    }
}

.image-gallery-bullets {
    left: 0;
    right: 0;
    width: 80%;
    z-index: 4;
    margin: 0 auto;
    position: absolute;

    .image-gallery-bullet {
        width: 5px;
        margin: 0 5px;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        display: inline-block;
        background-color: white;
        border: 1px solid white;
        transition: all .2s ease-out;

        &:focus {
            background: green;
            transform: scale(1.5);
            border: 1px solid green;
        }

        &.active {
            transform: scale(1.5);
            border: 1px solid green;
            background: green;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background: green;
                border: 1px solid green;
            }

            &.active:hover {
                background: green;
            }
        }
    }
}
