.swiper {
  width: auto;
  height: auto;
  padding: 0% 5%;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.swiper-slide {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
}


@media only screen and (min-width: 769px) {

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
    transform: translateX(-50%);
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
    transform: translateX(-55%);
  }
 
}


.swiper-button-next,
.swiper-button-prev {
    width: 40px;
    height: 40px;
    
    opacity: 0.3;
    padding: 10px;
    overflow: hidden;
    align-self: center;
    border-radius: 25px;
    box-sizing: border-box;
    background-color: green;
    transition: all 0.5s ease;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-prev:hover {
  opacity: 1;
}

.swiper-button-next {
    background-image: url(./assets/front.svg);
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: center;
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev {
    background-image: url(./assets/back.svg);
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: center;
    
}

.swiper-button-prev::after {
    display: none;
  }

.swiper-pagination-bullet-active {
    background-color: green;
}

.swiper-rtl .swiper-button-prev {
  transform: rotate(180deg);
}

.swiper-rtl .swiper-button-next {
  transform: rotate(180deg);
}