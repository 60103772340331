.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
    background-color: transparent;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
    background-color: transparent;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 0.5;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #28442c;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    /* margin-left: 300px; */
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #28442c;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}